.App {
  background: #121212 !important;
}

.body {
  background: #121212 !important;
  background-color: #121212 !important;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #dfdfdf;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif !important;
}

.h3 {
  font-weight: 600 !important;
}

h3 {
  font-weight: 600 !important;
}

.App-link {
  color: #09d3ac;
}

.ant-layout-sider {
  position: relative;
  min-width: 0;
  background: #121212 !important;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  width: 230px !important;
  max-width: 230px !important;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #121212 !important;
}

.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: #c7c9cb !important;
  line-height: 64px;
  background: #121212 !important;
}

.ant-layout {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
  flex: auto;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 0;
  background: #121212 !important;
}

.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(255, 255, 255, 0.65);
  line-height: 64px;
  background: #2b2c2c !important;
  margin-left: 20px !important;
  border-radius: 5px !important;
  margin-top: -5px !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  font-size: 20px !important;
}

.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  font-size: 20px !important;
}
