.ant-btn-primary {
  color: #fff;
  background-color: #3d6ee7 !important;
  border-color: #3d6ee7 !important;
  border-radius: 4px !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

.login-form-button {
  margin-top: 20%;
  width: 100% !important;
}

.media-controls button {
  margin-top: 25.3% !important;
  outline: 0;
  color: white !important;
  margin: 3px;
  padding: 6px 12px;
  border: 0;
  outline: 0;
    border-radius: 5px;
    display: inline-block;
    background-color: #2c2c2c;

}

.voicemail_holder {
  display: inline-block;
  width: 98%;
  max-height: 100vh;
  max-height: 100%;
}

.media-controls {
  margin-top: -32px !important;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  font-size: 20px !important;
}

.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  font-size: 20px !important;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0 16px;
  overflow: hidden;
  font-size: 20px !important;
  line-height: 40px;
  text-overflow: ellipsis;
}

.ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 15px !important;
  height: 15px !important;
  vertical-align: middle;
  border-radius: 50%;
}

.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(255, 255, 255, 0.65);
  font-size: 14px;
  background: #121212 !important;
}

.body {
  font-family: "Open Sans", sans-serif !important;
}

body {
  font-family: "Open Sans", sans-serif !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif !important;
}

.h3 h3 {
  font-weight: 600 !important;
}

.ant-divider {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  color: rgba(255, 255, 255, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: tnum, "tnum";
  font-feature-settings: tnum, "tnum";
  background: #c1c1c1 !important;
}

.label-themed {
  font-size: 16px;
  color: #c7c9cb;
  padding: 10px;
}

.input-showonly-themed {
  font-size: 18px;
  color: #c7c9cb;
  padding: 15px;
  font-weight: 600;
  background: #292929;
}

.col-5-homepage {
  min-height: 450px;
  background-color: #272727;
  padding: 15px;
  margin-right: 10px;
  border-radius: 5px;
}

.col-6-homepage {
  min-height: 450px;
  background-color: #272727;
  padding: 15px;
  border-radius: 5px;
}

.users-col-homepage {
  min-height: 450px;
  background-color: #272727;
  margin-top: 25px;
  padding: 15px;
  border-radius: 5px;
  width: calc(91.666667% + 10px);
  max-width: calc(91.666667% + 10px);
}

.users-col-userspage {
  min-height: 100vh;
  background-color: transparent;
  margin-top: 25px;
  padding: 15px;
  border-radius: 5px;
  width: 100%;
  max-width: 100%;
}

.lines-inline {
  width: 48%;
  display: inline-block;
  margin-right: 1%;
}

.lines-inline-userpage {
  width: 24%;
  display: inline-block;
  margin-right: 1%;
}

.lines-green {
  background: #162312;
  border: 1px solid;
  border-color: #274916;
  color: #c7c9cb;
  font-size: 16px;
  font-weight: 600;
  padding: 5px;
  height: 45px;
  margin-top: 5px;
  margin-bottom: 5px;
  line-height: 30px;
}

.lines-orange {
  background: #2b2111;
  border: 1px solid;
  border-color: #594214;
  color: #c7c9cb;
  font-size: 16px;
  font-weight: 600;
  padding: 5px;
  height: 45px;
  margin-top: 5px;
  margin-bottom: 5px;
  line-height: 30px;
}

.lines-red {
  background: #2a1215;
  border: 1px solid;
  border-color: #58181c;
  color: #c7c9cb;
  font-size: 16px;
  font-weight: 600;
  padding: 5px;
  height: 45px;
  margin-top: 5px;
  margin-bottom: 5px;
  line-height: 30px;
}

.lines-icon-green {
  color: #49aa19;
  font-size: 22px;
  margin-top: 5px;
  margin-right: 5px;
  display: inline-block;
}

.lines-icon-orange {
  color: #d89614;
  font-size: 22px;
  margin-top: 5px;
  margin-right: 5px;
  display: inline-block;
}

.lines-icon-red {
  color: #d32029;
  font-size: 22px;
  margin-top: 5px;
  margin-right: 5px;
  display: inline-block;
}

.lines-icon-green-logs {
  color: #49aa19;
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
}

.logs-callback {
  margin-top: -2px;
  float: right;
}

.logs-callback-button {
  border-color: #2c2c2c !important;
  background-color: #2c2c2c !important;
}

.lines-icon-red-logs {
  color: #d32029;
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
}

.inline {
  display: inline-block;
}

.right {
  float: right;
}

.left {
  float: left;
}

.margin-7-left {
  margin-left: 7px !important;
}

.text-center {
  text-align: center !important;
}

.ant-input-affix-wrapper {
  background-color: #141414 !important;
  border-radius: 5px !important;
}

.logs-label {
  font-weight: 600;
  display: inline-block;
  color: #149994;
  font-size: 18px;
  margin-right: 5px;
  margin-left: 10px;
}

.logs-inline-container {
  font-size: 16px;
  padding: 5px;
  color: #c7c9cb !important;
  height: 40px;
  background: #141414;
  border: 1px solid;
  border-color: #434343;
  padding: 5px;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.contact-card-name {
  display: inline-block;
  width: 65%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
}

.lines-name {
  display: inline-block;
  width: 40%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
}

// Menu

.sider-style {
  overflow: auto;
  height: 100vh;
  min-height: 100rem;
  max-height: 100%;
  position: fixed;
  left: 0;
  width: 230px;
  max-width: 230px;
}

.menu-item-square-top {
  width: 99px;
  height: 65px;
  text-align: center;
  line-height: 40px;
  margin-right: 5px;
  border-radius: 5px;
  display: inline-block;
  background-color: #2c2c2c;
}

.footer {
  margin-left: -230px !important;
}

input:-internal-autofill-selected {
  -webkit-appearance: initial;
  background: #141414 !important;
  background-color: #141414 !important;
  background-image: none !important;
  color: c7c9cb !important;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #141414 inset !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: #ffffffa6 !important;
  font-size: 14px !important;
}

.icon-menu-item-square-top {
  color: #149995;
  margin-top: 10px;
  font-size: 40px;
  display: inline-block;
  cursor: pointer;
}

.white {
  color: white !important;
}

.contact-card {
  color: #c7c9cb !important;
  height: 90px;
  background: #141414;
  border: 1px solid;
  border-color: #434343;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.lines-icon-green-contact-card {
  color: #49aa19;
  margin-top: 5px;
  margin-left: 5px;
  display: inline-block;
}

.lines-icon-orange-contact-card {
  color: #d89614;
  margin-top: 5px;
  margin-left: 5px;
  display: inline-block;
}

.lines-icon-red-contact-card {
  color: #d32029;
  margin-top: 5px;
  margin-left: 5px;
  display: inline-block;
}

.small-divider {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}

.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #cc1a14 !important;
  border-radius: 100%;
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  opacity: 0.3;
  -webkit-animation: antSpinMove 1s infinite linear alternate;
  animation: antSpinMove 1s infinite linear alternate;
}

.ant-spin {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(255, 255, 255, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: tnum, "tnum";
  font-feature-settings: tnum, "tnum";
  position: absolute;
  display: none;
  color: #b5b5b5 !important;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-tag-checkable-checked {
  background-color: #49aa19 !important;
}

.ant-tag-checkable {
  color: #49aa19;
  background: #162312;
  border-color: #274916 !important;
  font-size: 14px !important;
  margin-bottom: 2px;
}

.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #49aa19 !important;
}